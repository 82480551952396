import { SET_INFO_POPUP } from "../actions/infoPopupActions";

const initialState = {
  message: "",
};

export default function infoPopupReducer(state = initialState, action) {
  switch (action.type) {
    case SET_INFO_POPUP:
      return { ...state, message: action.payload };
    default:
      return state;
  }
}
