import React, { lazy } from "react";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/ru";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import FullPageLoader from "./components/FullPageLoader/FullPageLoader";
import { InfoPopup } from "./components/InfoPopup/InfoPopup";
import MetaTags from "./SEO/MetaTags";
import { PrivateRoute, OpenRoute } from "./Routes";
import { ROUTES } from "./constants";
import "./App.scss";

const LoginLayout = lazy(() => import("./layouts/LoginLayout/LoginLayout"));
const MainLayout = lazy(() => import("./layouts/MainLayout/MainLayout"));
const CreateOrderLayout = lazy(() => import("./layouts/CreateOrderLayout/CreateOrderLayout"));

const theme = createMuiTheme({
  palette: {
    type: "dark",
    background: { paper: "#282c34" },
    default: { main: "#b411d0" },
    primary: { main: "#42a5f5" },
    secondary: { main: "#fb8c00" },
  },
  breakpoints: { step: 100 },
  overrides: { MuiButton: { root: { borderRadius: 5 } } },
});

const App = () => {
  const isAuth = useSelector(({ profile }) => profile.isAuth);
  const globalLoading = useSelector(({ globalLoading }) => globalLoading.loading);

  return (
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale="ru">
        <Router>
          <InfoPopup />
          {globalLoading && <FullPageLoader withOverlay />}
          <MetaTags baseUrlsProp={{ ru: "https://admin.transpadent.org/" }} title="TranspaDent" />

          <Switch>
            <OpenRoute path={ROUTES.createOrder} component={CreateOrderLayout} />
            <OpenRoute exact path={ROUTES.home} component={LoginLayout} />
            <PrivateRoute path={ROUTES.home} component={MainLayout} auth={isAuth} />
          </Switch>
        </Router>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
};

export default App;
