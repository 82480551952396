import { API } from "../../modules/Api";
import { setInfoPopup } from "./infoPopupActions";

export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS";
export const UPDATE_ORDERS_SUCCESS = "UPDATE_ORDERS_SUCCESS";

export const getOrdersSuccess = (orders) => ({ type: GET_ORDERS_SUCCESS, payload: orders });

export const updateOrder = (id, data) => (dispatch, getState) => {
  const key = Object.keys(data).toString();
  const value = Object.values(data).toString();

  const newOrders = getState().orders.list.map((el) => {
    return el.id === id ? { ...el, [key]: value === "false" ? false : value } : el;
  });
  // dispatch(getSingleOrderLastActions(id));
  return dispatch({ type: UPDATE_ORDERS_SUCCESS, payload: newOrders });
};

// ============ getSingleOrder
export const GET_SINGLE_ORDER_START = "GET_SINGLE_ORDER_START";
export const GET_SINGLE_ORDER_SUCCESS = "GET_SINGLE_ORDER_SUCCESS";
export const GET_SINGLE_ORDER_ERROR = "GET_SINGLE_ORDER_ERROR";

export const getSingleOrderStart = () => ({
  type: GET_SINGLE_ORDER_START,
});

export const getSingleOrderSuccess = (order) => ({
  type: GET_SINGLE_ORDER_SUCCESS,
  payload: order,
});

export const getSingleOrderError = (error) => ({
  type: GET_SINGLE_ORDER_ERROR,
  payload: error,
});

export const getSingleOrder = (id) => {
  return async (dispatch) => {
    dispatch(getSingleOrderStart());
    try {
      const { data: order } = await API.get(`/order/${id}`);
      dispatch(getSingleOrderSuccess(order));
    } catch (error) {
      dispatch(setInfoPopup("Ошибка"));
    }
  };
};

// ============ getSingleOrderLastActions
export const GET_SINGLE_ORDER_LAST_ACTIONS_SUCCESS = "GET_SINGLE_ORDER_LAST_ACTIONS_SUCCESS";

export const getSingleOrderLastActionsSuccess = (orderID, lastActions) => ({
  type: GET_SINGLE_ORDER_LAST_ACTIONS_SUCCESS,
  payload: { orderID, lastActions },
});

export const getSingleOrderLastActions = (id) => {
  return async (dispatch) => {
    try {
      const { data: lastActions } = await API.get(`/order_log/order/${id}/last`);
      dispatch(getSingleOrderLastActionsSuccess(id, lastActions));
    } catch (error) {
      dispatch(setInfoPopup("Ошибка"));
    }
  };
};

// ============ dirtyFields
export const SET_DIRTY_FIELDS = "SET_DIRTY_FIELDS";
export const REMOVE_DIRTY_FIELDS = "REMOVE_DIRTY_FIELDS";
export const CLEAR_DIRTY_FIELDS = "CLEAR_DIRTY_FIELDS";

export const setDirtyFields = (orderID, fieldNamesArray) => ({
  type: SET_DIRTY_FIELDS,
  payload: { orderID, fieldNamesArray },
});
export const removeDirtyFields = (orderID, fieldNamesArray) => ({
  type: REMOVE_DIRTY_FIELDS,
  payload: { orderID, fieldNamesArray },
});
export const clearDirtyFields = (orderID) => ({ type: CLEAR_DIRTY_FIELDS, payload: { orderID } });

// ============ Global Saving
export const SET_GLOBAL_DTO = "SET_GLOBAL_DTO";
export const REMOVE_FROM_GLOBAL_DTO = "REMOVE_FROM_GLOBAL_DTO";
export const UPDATE_SINGLE_ORDER = "UPDATE_SINGLE_ORDER";

export const updateSingleOrder = (order) => {
  console.log("update order", order)
   return {
  type: UPDATE_SINGLE_ORDER,
  payload: { order },
}};

export const setGlobalDTO = (orderID, fieldAPI, value) => ({
  type: SET_GLOBAL_DTO,
  payload: { orderID, newField: { [fieldAPI]: value } },
});
export const removeFromGlobalDTO = (orderID, fieldAPI) => (dispatch, getState) => {
  const globalDTO = getState().orders.globalDTO[orderID];
  delete globalDTO[fieldAPI];
  return dispatch({
    type: REMOVE_FROM_GLOBAL_DTO,
    payload: { orderID, globalDTO },
  });
};

export const globalSave = (orderID) => async (dispatch, getState) => {
  const globalDTO = getState().orders.globalDTO[orderID];
  try {
    const { data: order } = await API.patch(`/order/${orderID}`, globalDTO);
    dispatch(updateSingleOrder(order));
    dispatch(getSingleOrderLastActions(orderID));
    dispatch(setInfoPopup("Сохранено"));
  } catch (error) {
    dispatch(setInfoPopup("Ошибка"));
  }
};
