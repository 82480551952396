import React from "react";
import { IconButton, Snackbar } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { setInfoPopup } from "../../redux/actions/infoPopupActions";

export const InfoPopup = () => {
  const dispatch = useDispatch();
  const infoPopup = useSelector(({ infoPopup }) => infoPopup);

  function closeInfoPopup() {
    dispatch(setInfoPopup(""));
  }

  return (
    <Snackbar
      className="infoBox"
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={!!infoPopup.message}
      autoHideDuration={4000}
      onClose={(e, reason) => {
        if (reason !== "clickaway") closeInfoPopup();
      }}
      message={infoPopup.message}
      action={
        <IconButton size="small" color="inherit" onClick={closeInfoPopup}>
          <Close fontSize="small" />
        </IconButton>
      }
    />
  );
};
