import React from "react";
import { CircularProgress } from "@material-ui/core";

import "./FullPageLoader.scss";

const FullPageLoader = ({ withOverlay }) => {
  return (
    <div className={`fullPageLoader ${withOverlay ? "fullPageLoader_withOverlay" : ""}`}>
      <CircularProgress color="secondary" size={120} />
    </div>
  );
};

export default FullPageLoader;
