import { TOGGLE_SIDEBAR } from "../actions/sidebarActions";

const initialState = {
  opened: false,
};

export default function sidebarReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return { ...state, opened: !state.opened };
    default:
      return state;
  }
}
