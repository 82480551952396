import {
  CLEAR_FINAL_SUM,
  GET_FINAL_SUM_ERROR,
  GET_FINAL_SUM_START,
  GET_FINAL_SUM_SUCCESS,
  GET_MATERIALS_ERROR,
  GET_MATERIALS_START,
  GET_MATERIALS_SUCCESS,
  SET_MATERIALS_ERROR,
  SET_MATERIALS_START,
  SET_MATERIALS_SUCCESS,
} from "../actions/materials.actions";

const initialState = {
  getMaterialsLoading: false,
  materials: [],
  setMaterialsLoading: false,
  getFinalSumListLoading: false,
  finalSumList: [],
  finalPrice: null,
};

function calculateFinalPrice(payload) {
  if (payload.length === 1) {
    return Number(payload[0].price_sum).toFixed(2);
  }
  if (payload.length > 1) {
    return payload
      .reduce((previousValue, currentValue) => {
        return Number(previousValue) + Number(currentValue.price_sum);
      }, 0)
      .toFixed(2);
  }
  return 0;
}

export default function materialsReducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_MATERIALS_START:
      return { ...state, getMaterialsLoading: true };
    case GET_MATERIALS_SUCCESS:
      return { ...state, getMaterialsLoading: false, materials: payload };
    case GET_MATERIALS_ERROR:
      return { ...state, getMaterialsLoading: false };

    case SET_MATERIALS_START:
      return { ...state, setMaterialsLoading: true };
    case SET_MATERIALS_SUCCESS:
      return { ...state, setMaterialsLoading: false, materials: payload };
    case SET_MATERIALS_ERROR:
      return { ...state, setMaterialsLoading: false };

    case GET_FINAL_SUM_START:
      return { ...state, getFinalSumListLoading: true };
    case GET_FINAL_SUM_SUCCESS:
      return {
        ...state,
        getFinalSumListLoading: false,
        finalSumList: payload,
        finalPrice: calculateFinalPrice(payload),
      };
    case GET_FINAL_SUM_ERROR:
      return { ...state, getFinalSumListLoading: false };

    case CLEAR_FINAL_SUM:
      return { ...state, finalSumList: [], finalPrice: null };

    default:
      return state;
  }
}
