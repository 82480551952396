import { combineReducers } from "redux";

import infoPopupReducer from "./infoPopupReducer";
import globalLoadingReducer from "./globalLoadingReducer";
import profileReducer from "./profileReducer";
import sidebarReducer from "./sidebarReducer";
import ordersReducer from "./orders.reducer";
import { createDialogsReducer } from "./dialogs.reducer";
import materialsReducer from "./materials.reducer";

export default combineReducers({
  infoPopup: infoPopupReducer,
  globalLoading: globalLoadingReducer,
  materials: materialsReducer,
  orders: ordersReducer,
  profile: profileReducer,
  sidebar: sidebarReducer,
  ...createDialogsReducer(),
});
