import Logger from "./Logger";
import axios from "axios";

const STATUS_CODE_400 = 400;
const STATUS_CODE_401 = 401;

export const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

API.interceptors.request.use(
  function (config) {
    config.headers.common[process.env.REACT_APP_AUTH_HEADER] = localStorage.getItem(process.env.REACT_APP_AUTH_HEADER);
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

API.interceptors.response.use(
  function (response) {
    if (response.headers[process.env.REACT_APP_AUTH_HEADER]) {
      localStorage.setItem(process.env.REACT_APP_AUTH_HEADER, response.headers[process.env.REACT_APP_AUTH_HEADER]);
    }
    return response;
  },
  function (error) {
    let responseError;
    if (STATUS_CODE_401 === error.response.status) {
      localStorage.removeItem(process.env.REACT_APP_AUTH_HEADER);
      responseError = error.response.data.error;
    } else if (STATUS_CODE_400 === error.response.status) {
      responseError = Object.keys(error.response.data.data.error).map((el) => ({
        name: el,
        error: error.response.data.data.error[el],
      }));
    } else {
      Logger.info(error);
      responseError = ["uncaught error"];
    }
    return Promise.reject(responseError);
  },
);
