import {
  SET_AUTH,
  START_LOGIN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  SET_LOADING_PROFILE,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_ERROR,
  CLEAR_PROFILE,
} from "../actions/profileActions";

const initialState = {
  loading: false,
  isAuth: !!localStorage.getItem(process.env.REACT_APP_AUTH_HEADER),
  id: null,
  first_name: null,
  last_name: null,
  job_title: null,
  is_admin: null,
  avatar: null,
  errors: null,
  permissions: null,
};

export default function profileReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_AUTH:
      return { ...state, isAuth: payload };
    case START_LOGIN:
      return {
        ...state,
        loading: true,
        isAuth: false,
        errors: [],
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuth: true,
        ...payload,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        loading: false,
        isAuth: false,
        errors: payload,
      };
    case SET_LOADING_PROFILE:
      return {
        ...state,
        loading: true,
      };
    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        ...payload,
      };
    case GET_PROFILE_ERROR:
      return {
        ...state,
        loading: false,
        errors: payload,
      };
    case CLEAR_PROFILE:
      return initialState;
    default:
      return state;
  }
}
